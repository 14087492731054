import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyDNZiXf4tI6lJY-lmY8I8yq9Imy7o4oxes",
  authDomain: "okblogging.firebaseapp.com",
  projectId: "okblogging",
  storageBucket: "okblogging.appspot.com",
  messagingSenderId: "691779156269",
  appId: "1:691779156269:web:2ddbca9e37ba336de1bba4"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();
